<template>
  <div v-if="isReady">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('waterquality.parameter_list') }}
          </span>
        </h3>

        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li>
              <b-button class="btn btn-sm btn-info" @click="showAddModal()">{{ $t('GENERAL.add') }}</b-button>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center" v-if="data">
            <thead>
              <tr>
                <th class="p-0" style="width: 30%">
                  {{ $t('waterquality.parameter_name') }}
                </th>
                <th class="p-0" style="width: 20%">
                  {{ $t('waterquality.parameter_unit') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('GENERAL.last_update') }}
                </th>
                <th class="p-0 text-right" style="min-width: 10%">
                  {{ $t('GENERAL.option') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in data">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.name }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.unit ? item.unit : '-' | str_limit(128) }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <div>
                      <a class="text-muted font-weight-bold">
                        {{ item.lu_datetime }}
                      </a>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <b-button @click="showEditModal(item.id)" class="btn btn-icon btn-light btn-sm mx-3">
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </b-button>
                    <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center" v-else>No Data</div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
      <ParameterAddDataComp ref="add_modal_comp"></ParameterAddDataComp>
    </div>
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';
import ParameterAddDataComp from '../components/Parameter_add_data_comp.vue';

export default {
  components: {
    ParameterAddDataComp,
  },
  name: 'waterquality_parameter_list',
  data() {
    return {
      isReady: false,
      data: null,
      total_post: 0,
      isShowModal: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Water Quality Data', route: {name: 'waterquality.list'}},
      {title: 'Parameter'},
    ]);

    this.$store
      .dispatch(Services.GET_PARAMETER_LIST)
      .then((res) => {
        this.isReady = true;
        this.data = res.data;
        this.total_post = res.data.length;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  methods: {
    reloadData() {
      this.isReady = false;
      this.$store
        .dispatch(Services.GET_PARAMETER_LIST)
        .then((res) => {
          this.isReady = true;
          this.data = res.data;
          this.total_post = res.data.length;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    showAddModal() {
      this.$refs.add_modal_comp.showModal({mode: 'add'});
    },
    showEditModal(id) {
      this.$refs.add_modal_comp.showModal({mode: 'edit', id: id});
    },
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            // console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(Services.DELETE_PARAMETER, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(Services.GET_PARAMETER_LIST)
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.data = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                // console.log(error);
                // Swal.fire({
                //     title: '',
                //     text: error,
                //     icon: 'error',
                //     confirmButtonClass: 'btn btn-secondary',
                //     heightAuto: false,
                // });
              });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
